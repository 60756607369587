<template>
    <div v-if="links.length">
        <h3 class="font-heading mb-4 sm:mb-6" v-text="title"/>
        <ul class="space-y-2 sm:space-y-4 pl-1">
            <li v-for="link in links">
                <Link
                    class="text-sm font-bold hover:text-indigo-500"
                    :href="link.url"
                    v-text="link.title"
                />
            </li>
        </ul>
    </div>
</template>

<script>
import {Link} from "@inertiajs/vue3";

export default {
    components: {Link},
    props: {
        title: String,
        links: Array,
    }
}
</script>
