<template>
    <div :class="styleClasses" class="p-4 border-2">
        <slot/>
    </div>
</template>

<script>
export default {
    props: {
        type: {
            default: 'success'
        }
    },
    data () {
        const colors = {
            success: 'bg-green-100 border-green-300',
            warning: 'bg-yellow-100 border-yellow-300',
            info: 'bg-blue-100 border-blue-300',
            danger: 'bg-red-100 border-red-300'
        };

        return {
            styleClasses: colors[this.type],
        }
    }
}
</script>
