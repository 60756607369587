<template>
    <Alert v-if="deletedProducts?.length" type="warning" class="mb-5 text-sm font-bold">
        Товари {{ deletedProducts.map(title => `"${title}"`).join(', ') }}
        більше недоступні для замовлення, тому видалені з кошика 😩
    </Alert>
</template>

<script>
import Alert from "@/Components/Alert.vue";

export default {
    components: {Alert},
    props: {
        deletedProducts: Array,
    }
}
</script>
