<template>
    <div>
        <Head v-if="seo || headTitle || title">
            <title>{{ seoTitle }}</title>
            <meta name="description" :content="seo?.description || 'Маркетплейс кави CupCult: кава в зернах та мелена, дріпи, обладнання для приготування смачної кави вдома. КапКульт для справжніх поціновувачів кави.'">

<!--            <meta property="og:title" :content="seoTitle" />-->
<!--            <meta property="og:site_name" :content="$page.props.appName">-->
<!--            <meta property="og:type" content="video.movie" />-->
<!--            <meta property="og:url" content="https://www.imdb.com/title/tt0117500/" />-->
<!--            <meta property="og:image" content="https://ia.media-imdb.com/images/rock.jpg" />-->
        </Head>

        <NewsletterPopup/>

        <Nav
            :is-company="isCompany"
            :menu="menu"
            :registration-state="registrationState"
            :force-show-sidebar="forceShowSidebar"
            @open-menu="menuOpened = true"
            @open-cart="cartSidebarOpened = true"
        />

        <NavSidebar
            v-model="menuOpened"
            :is-company="isCompany"
            :menu="menu"
            :info-pages="infoPages"
            :seller-pages="sellerPages"
        />

        <!-- Page Content -->
        <main :class="`mt-${mainMarginTop || 20}`">
            <!-- Page Heading -->
            <header v-if="title">
                <Container class="p-10">
                    <h3 class="text-2xl font-heading mb-2">{{ title }}</h3>
                </Container>
            </header>

            <slot/>
        </main>

        <Footer
            :is-company="isCompany"
            :menu="menu"
            :year="year"
            :info-pages="infoPages"
            :show-footer="showFooter"
            :seller-pages="sellerPages"
            :registration-state="registrationState"
        />

        <SocialMediaCollapseButton v-if="showSocials && !isCompany"/>
        <BackToTopButton/>

        <CartSidebar v-model="cartSidebarOpened" :cart="$page.props.cart || {}"/>
    </div>
</template>

<script>
import {Head} from '@inertiajs/vue3';
import SocialMediaCollapseButton from "@/Layouts/Partials/SocialMediaCollapseButton.vue";
import BackToTopButton from "@/Layouts/Partials/BackToTopButton.vue";
import CartSidebar from "@/Layouts/Partials/CartSidebar.vue";
import {clearBodyLocks} from 'tua-body-scroll-lock'
import {year} from "@/Helpers/dayjs";
import Nav from "@/Layouts/Partials/Nav.vue";
import Footer from "@/Layouts/Partials/Footer.vue";
import {createToast} from "mosha-vue-toastify";
import 'mosha-vue-toastify/dist/style.css'
import Container from "@/Components/Base/Container.vue";
import NavSidebar from "@/Layouts/Partials/NavSidebar.vue";
import NewsletterPopup from "@/Components/Marketing/NewsletterPopup.vue";

export default {
    components: {
        NewsletterPopup,
        NavSidebar,
        Container,
        Footer,
        Nav,
        Head,
        SocialMediaCollapseButton,
        BackToTopButton,
        CartSidebar
    },
    props: {
        title: {
            type: String,
            required: false
        },
        headTitle: {
            type: String,
            required: false
        },
        registrationState: {
            type: Boolean,
            default: false
        },
        showFooter: {
            type: Boolean,
            default: true
        },
        showSocials: {
            type: Boolean,
            default: true
        },
        forceShowSidebar: {
            type: Boolean,
            default: false
        },
        currentTime: String,
        mainMarginTop: String,
        seo: Object
    },
    data() {
        const isCompany = this.$page.props.isCompany

        return {
            isCompany,
            year: year(this.currentTime),
            menuOpened: false,
            cartSidebarOpened: false,
            menu: this.$page.props.categories,
            infoPages: isCompany ? [] : [
                this.pageLink('Про нас', 'about'),
                this.pageLink('Доставка і оплата', 'delivery-and-payments'),
                this.pageLink('Угода користувача', 'public-offer'),
                this.pageLink('Повернення та обмін', 'returns'),
                this.pageLink('Політика конфіденційності', 'privacy-policy'),
                this.pageLink('Контакти', 'contacts'),
            ],
            sellerPages: isCompany ? [] : [
                this.pageLink('Договір-оферта', 'seller-public-offer'),
                this.pageLink('Правила роботи', 'seller-rules')
            ]
        }
    },
    computed: {
        seoTitle() {
            return this.seo?.title || `${this.headTitle || this.title} - ${this.$page.props.appName}`
        }
    },
    methods: {
        listenData(event) {
            const message = event.detail.page.message

            if (message) {
                createToast(message, {
                    position: 'bottom-right',
                    hideProgressBar: true
                })
            }
        },
        pageLink(title, slug) {
            return {
                title,
                url: this.route('pages', slug)
            }
        }
    },
    created() {
        clearBodyLocks()

        this.emitter.on('openCart', () => this.cartSidebarOpened = true)
    },
    mounted() {
        document.addEventListener('inertia:success', this.listenData)
    },
    beforeUnmount() {
        document.removeEventListener('inertia:success', this.listenData)
    }
}
</script>
