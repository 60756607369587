<template>
    <div v-if="showPopup"
         class="min-w-screen h-screen fixed left-0 top-0 flex justify-center items-center inset-0 z-50"
    >
        <div class="absolute bg-black opacity-80 inset-0 z-0" @click="hidePopup"></div>
        <div class="w-full mx-3 relative max-w-lg">
            <form class="bg-white" @submit.prevent="submit">
                <div>
                    <img v-if="isFirstStep" loading="lazy" src="@/../images/marketing/coupon-step1.webp" class="w-full">
                    <img v-else loading="lazy" src="@/../images/marketing/coupon-step2.webp" class="w-full">

                    <div
                        v-if="!isFirstStep"
                        @click="isFirstStep = true"
                        class="absolute bg-white opacity-50 cursor-pointer hover:opacity-100 w-10 h-10 top-3 left-3 flex items-center justify-center"
                    >
                        <Chevron width="25" class="rotate-180"/>
                    </div>

                    <div
                        @click="hidePopup"
                        class="absolute bg-white opacity-50 cursor-pointer hover:opacity-100 w-10 h-10 top-3 right-3 flex items-center justify-center"
                    >
                        <Cross width="25"/>
                    </div>
                </div>

                <div v-if="isFirstStep">
                    <div class="p-7 sm:p-10">
                        <h3 class="font-heading sm:text-2xl mb-5">
                            Хочеш {{ $page.props.couponDiscountPercent }}% знижки на перше замовлення?
                        </h3>

                        <PrimaryButton class="my-5" @click.prevent="isFirstStep = false">
                            так, звісно
                        </PrimaryButton>
                        <PrimaryButton @click.prevent="hidePopup"
                                       color="white"
                                       text-color="gray-500"
                                       hover-color="white">
                            ні, дякую
                        </PrimaryButton>

                        <p class="text-xs text-gray-400 mt-5">
                            * знижка доступна тільки на категоріїї Зерно та Дріпи пронягом місяця з моменту отримання
                            промокоду
                        </p>
                    </div>
                </div>

                <div v-else class="flex-auto justify-center">
                    <div class="p-10">
                        <h3 class="font-heading text-2xl mb-5">Круто!</h3>

                        <InputLabel value="Куди ми повинні відправити код?" class="mb-2"/>
                        <TextInput
                            type="email"
                            placeholder="Введи свій email"
                            v-model="form.email"
                        />

                        <InputError class="mt-2" :message="form.errors.email"/>

                        <PrimaryButton :loading="form.processing" class="mt-5">
                            відправити мій код
                        </PrimaryButton>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import TextInput from "@/Components/TextInput.vue";
import PrimaryButton from "@/Components/PrimaryButton.vue";
import {useForm} from "@inertiajs/vue3";
import InputError from "@/Components/InputError.vue";
import InputLabel from "@/Components/InputLabel.vue";
import Chevron from "@/Components/Svg/Chevron.vue";
import {createToast} from "mosha-vue-toastify";
import NewsletterSubscribed from "@/Components/Toasts/NewsletterSubscribed.vue";
import Cross from "@/Components/Svg/Cross.vue";

export default {
    components: {Cross, Chevron, InputLabel, InputError, PrimaryButton, TextInput},
    data() {
        return {
            ignoringSources: [
                'privat',
                'fishka',
            ],

            showPopup: false,
            isFirstStep: true,
            form: useForm({
                email: null
            }),
            bonusInfo: JSON.parse(localStorage.getItem('bonus')) || {}
        }
    },
    methods: {
        submit() {
            this.form.post(this.route('newsletter.store'), {
                onSuccess: () => {
                    this.showPopup = false

                    this.hideForever()

                    createToast(NewsletterSubscribed, {
                        position: 'bottom-right',
                        hideProgressBar: true
                    })
                },
            })
        },
        hidePopup() {
            this.showPopup = false

            this.updateBonusInfo({close: new Date().getTime()})
        },
        updateBonusInfo(info) {
            localStorage.setItem('bonus', JSON.stringify({...this.bonusInfo, ...info}))
        },
        hideForever() {
            this.updateBonusInfo({
                hideForever: true
            })
        }
    },
    mounted() {
        if (this.bonusInfo.hideForever) {
            return;
        }

        if (this.ignoringSources.includes((new URLSearchParams(window.location.search)).get('utm_source')) || this.$page.props.auth.user) {
            return this.hideForever()
        }

        // 7 days
        if ((new Date().getTime() - (this.bonusInfo.close || 0)) > 604800000) {
            setTimeout(() => this.showPopup = true, 8000)
        }
    }
}
</script>
