<script>
import {defineComponent} from 'vue'
import VueNumberInput from "@chenfengyuan/vue-number-input";

export default defineComponent({
    name: "NumberInput",
    components: {VueNumberInput},
    methods: {
        selectAllAndPutCursorToEnd (e) {
            if (e.target.type === 'number') {
                setTimeout(() => e.target.select());
            }
        }
    }
})
</script>

<template>
    <vue-number-input
        @focusin="selectAllAndPutCursorToEnd"
    />
</template>

<style>
.input-small.vue-number-input {
    width: 7rem;
}

.input-small .vue-number-input__input {
    height: 2rem;
}

.input-small input {
    padding: 0!important;
}

.vue-number-input__input {
    border: 2px solid #000!important;
    height: 3rem;
    padding-top: 0.75rem!important;
    padding-bottom: 0.75rem!important;
    font-size: 0.875rem!important;
    line-height: 1.25rem!important;
    font-weight: 700;
}

.vue-number-input__button--minus {
    border-right: 2px solid #000!important;
    left: 2px!important;
}

.vue-number-input__button--plus {
    border-left: 2px solid #000!important;
    right: 2px!important;
}

.input-small .vue-number-input__button {
    width: 2rem!important;
}

.vue-number-input__button {
    bottom: 2px!important;
    top: 2px!important;
    width: 3rem!important;
}

.vue-number-input__button:after {
    width: 2px!important;
}

.vue-number-input__button:before {
    height: 2px!important;
}
</style>
