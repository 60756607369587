<template>
    <div>
        <button
            v-if="showBackToTopButton"
            @click="scrollToTop"
            class="fixed bottom-20 right-4 bg-indigo-500 hover:bg-indigo-600 text-white rounded-full z-10 w-12 h-12 flex justify-center items-center"
        >
            <Chevron class="-rotate-90 h-4"/>
        </button>
    </div>
</template>

<script>
import Chevron from "@/Components/Svg/Chevron.vue";

export default {
    components: {Chevron},
    data() {
        return {
            showBackToTopButton: false,
        };
    },
    methods: {
        checkScrollPosition() {
            window.addEventListener("scroll", this.toggleBackToTopButton);
        },
        toggleBackToTopButton() {
            this.showBackToTopButton = window.scrollY >= 500;
        },
        scrollToTop() {
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        },
    },
    mounted() {
        this.checkScrollPosition();
    },
    beforeDestroy() {
        window.removeEventListener("scroll", this.toggleBackToTopButton);
    },
};
</script>
