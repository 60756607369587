<template>
    <div>
        <button
            @click="toggleCollapse"
            aria-label="Соцмережі"
            class="fixed bottom-4 right-4 bg-indigo-500 hover:bg-indigo-600 rounded-full text-white shadow-lg z-10 w-12 h-12 flex items-center justify-center"
        >
            <Component
                v-if="collapsed"
                :is="socials[currentSocialIndex].icon"
                :name="socials[currentSocialIndex].icon"
                class="transition-all duration-300 w-6 h-6"
                :class="isLeaving ? 'opacity-0' : 'opacity-100'"
            />
            <Cross v-else width="25"/>
        </button>

        <div key="social-links" class="fixed right-4 z-20 transition-all duration-500"
             :class="collapsed ? '-bottom-32' : 'bottom-20'">
            <ul class="space-y-4">
                <li
                    v-for="social in socials"
                    class="w-12 h-12 bg-indigo-500 rounded-full text-white leading-12 hover:bg-indigo-600 flex items-center justify-center"
                >
                    <a :href="social.link" target="_blank" :aria-label="social.icon">
                        <Component :is="social.icon" class="h-5"/>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import Cross from "@/Components/Svg/Cross.vue";
import Telegram from "@/Components/Svg/Telegram.vue";
import Instagram from "@/Components/Svg/Instagram.vue";

export default {
    components: {Cross, Telegram, Instagram},
    data() {
        return {
            collapsed: true,
            currentSocialIndex: 0,
            isLeaving: false,
            socials: [
                {
                    icon: 'Telegram',
                    link: 'https://t.me/cupcult_helpbot?start=start'
                },
                {
                    icon: 'Instagram',
                    link: 'https://www.instagram.com/cup.cult/'
                }
            ]
        };
    },
    methods: {
        toggleCollapse() {
            this.collapsed = !this.collapsed;
        },
        toggleIcon() {
            this.isLeaving = true;
            setTimeout(() => {
                this.currentSocialIndex = (this.currentSocialIndex + 1) % this.socials.length;
                setTimeout(() => {
                    this.isLeaving = false;
                }, 300);
            }, 300);
        },
        changeIconEverySecond() {
            setInterval(this.toggleIcon, 2000);
        },
    },
    created() {
        this.changeIconEverySecond();
    },
};
</script>
